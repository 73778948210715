.geolocate-active::-webkit-input-placeholder {
  color: #dc0c23 !important;
  font-weight: 500;
  font-size: 18px;
}

.geolocate-active:-ms-input-placeholder {
  color: #dc0c23 !important;
  font-weight: 500;
  font-size: 18px;
}

.geolocate-active::-ms-input-placeholder {
  color: #dc0c23 !important;
  font-weight: 500;
  font-size: 18px;
}
