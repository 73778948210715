footer {
  position: absolute;
  clear: both;
  width: 100%;
  height: auto;
  /* bottom: -100px; */
  background: #e8e8e8;

  min-height: 40px;
}

.footer-container {
  width: 60%;
  margin: auto;
}

.footer-socials {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  padding-bottom: 10px;
}

.footer-socials--icons {
  padding: 3px 10px;
  background: #000;
  color: #fff;
  border-radius: 0px 8px 0px 0px;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 20px;
}

.footer-links {
  display: inline;
  margin: 0 10px;
  font-size: 1rem;
}

.footer-link {
  color: #000;
  text-decoration: none;
  font-size: 12px;
}

.footer-copyright {
  text-align: right;
  color: #000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    /* bottom: -70vh; */
    background: #e8e8e8;
    min-height: 40px;
  }

  .footer-container {
    width: 60%;
    margin: auto;
  }

  .footer-socials {
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .footer-socials--icons {
    padding: 3px 10px;
    background: #000;
    color: #fff;
    border-radius: 0px 8px 0px 0px;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 20px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }

  .footer-copyright {
    text-align: left;
    color: #000;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    /* bottom: -35vh; */
    background: #e8e8e8;
    min-height: 40px;
  }

  .footer-container {
    width: 60%;
    margin: auto;
  }

  .footer-socials {
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .footer-socials--icons {
    padding: 3px 10px;
    background: #000;
    color: #fff;
    border-radius: 0px 8px 0px 0px;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 20px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }

  .footer-copyright {
    text-align: left;
    color: #000;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    /* bottom: -25vh; */
    background: #e8e8e8;
    min-height: 40px;
  }

  .footer-container {
    width: 60%;
    margin: auto;
  }

  .footer-socials {
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .footer-socials--icons {
    padding: 3px 10px;
    background: #000;
    color: #fff;
    border-radius: 0px 8px 0px 0px;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 20px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }

  .footer-copyright {
    text-align: right;
    color: #000;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .footer-links {
    display: inline;
    margin: 0 10px;
    font-size: 1rem;
  }
}
