.storePreview {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #ccc;
  cursor: pointer;
}

.storePreview__storeName {
  font-weight: 500;
  font-size: 16px;
}

.storePreview__storeAddressLines {
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .storePreview__storeName {
    font-weight: 500;
    font-size: 1rem;
  }

  .storePreview__storeAddressLines {
    font-size: 0.74rem;
  }
}
