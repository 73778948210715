.Collapsible {
  padding: 0 0.6rem;
}
.Collapsible__trigger {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.Collapsible__contentInner {
  /* margin-top: 10px; */
}

.open-close-icon {
  position: absolute;
  right: 10px;
}

.categories {
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  margin-top: 5px;
}

.categories-logo {
  padding: 10px;
  border: 1px solid #6e3cbe;
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .categories-logo {
    width: 50%;
  }
}

.categories-logo--KDIT-Gold {
  border: 1px solid #f5b400;
}

.categories-logo--KDIT-Silver {
  border: 1px solid #585858;
}

.categories-logo--KDIT-Platinum {
  border: 1px solid #6e3cbe;
}

.categories-logo--KDIT-KEP {
  border: 1px solid #f06400;
}

.categories-logo--KDIT-Supply-Distributor {
  border: 1px solid #282828;
}

.categories-logo--KDIT-Production-Printing-Partner {
  border: 1px solid #0a9bcd;
}

.categories-logo--active {
  background-color: #6e3cbe;
}

.categories-logo--active--KDIT-Gold {
  background-color: #f5b400;
}

.categories-logo--active--KDIT-Silver {
  background-color: #585858;
}

.categories-logo--active--KDIT-Platinum {
  background-color: #6e3cbe;
}

.categories-logo--active--KDIT-KEP {
  background-color: #f06400;
}

.categories-logo--active--KDIT-Supply-Distributor {
  background-color: #282828;
}

.categories-logo--active--KDIT-HW-Distributor {
  background-color: #0a9bcd;
}
.certificates {
  font-size: 14px;
  color: #000;
  margin-bottom: 1px;
}
.certificates-line {
  margin: 5px;
}

.certificates-name {
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.certificates-name--active {
  background-color: aliceblue;
}
