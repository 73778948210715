.infoWindow {
  width: 230px;
  background: #ffffff;
  color: #1d1d1d;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  min-height: 80px;
  line-height: 1.2rem;
  font-size: 15px;
  padding-bottom: 15px;
}

.infoWindow::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 90px;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 20px 0 3px 0;
  background: #fff;
}

.infoWindow__storeName {
  font-size: 0.9rem;
  font-weight: 500;
  vertical-align: middle;
  background: rgb(241, 241, 241);
  margin: 0;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
}

.infoWindow__addressTitle,
.infoWindow__contactTitle {
  margin-bottom: 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.infoWindow__addressTitle,
.infoWindow__contactTitle,
.infoWindow__addressLines,
.infoWindow__addressCity,
.infoWindow__contactPhone,
.infoWindow__contactWebsite,
.infoWindow__openingHours {
  padding: 0 15px;
}

.infoWindow__contactWebsite a {
  color: #008248;
}

.infoWindow__openingHours {
  padding-top: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #008248;
}
