.home {
  height: calc(100vh - 80px);
  position: relative;
}

.home-container {
  width: 80%;
  margin: auto;
  height: 100%;
}

.hovering-image {
  position: absolute;
  height: auto;
  /* right: 13%; */
  left: calc(75vw - 20vw);
  top: calc(50% - 10%);
  display: block;
}

.hovering-image > img {
  width: 35vh;
}
.home-column-text {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  display: inline-block;
  text-align: left;
}

.home-accessMap {
  position: absolute;

  height: auto;
  /* right: 13%; */
  left: calc(80vw - 15vw);
  /* top: calc(50% - 10%); */
  float: right;
}

.home-accessMap {
  background: #0a9bcd;
  display: inline-block;
  padding: 1vh 3vh;
  color: white;
  font-size: 2vh;
}

.home-accessMap > a {
  color: white;
  text-decoration: none;
}

.home-accessMap > a:hover {
  color: white;
  text-decoration: none;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .hovering-image {
    display: none;
  }
  .home-column-text {
    font-size: 17px;
    color: #000;
    font-weight: 600;
    display: inline-block;
    text-align: left;
  }

  .home-accessMap {
    position: relative;

    /* height: auto; */
    /* right: 13%; */
    left: calc(0vw - 35px);
    top: -10px;
    /* right: 0; */
    /* margin: 10px; */
    font-size: 10px;
    /* top: calc(50% - 10%); */
    /* float: right; */
  }
}

@media only screen and (min-width: 1920px) {
  .home-column-text {
    font-size: 3.5vh;
    color: #000;
    font-weight: 600;
    display: inline-block;
    text-align: left;
  }
}
