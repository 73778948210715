.input {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  padding-right: none !important;
}

.autocomplete-input {
  width: 85%;
  border: none;
}

input:focus {
  outline: none;
}

.icon-search-active {
  margin-right: 20px !important;
}

.icon-close-inactive {
  display: none !important;
}

.icon-close-active {
  display: inline;
  margin-left: 10px !important;
}

.icon {
  pointer-events: initial !important;
  color: #000 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

::-webkit-input-placeholder {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px;
}

:-ms-input-placeholder {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px;
}

::-ms-input-placeholder {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px;
}

.is-medium.input {
  font-size: 1rem !important;
  color: #000;
}

.control.has-icons-right .input {
  padding-right: 4em !important;
}

.predictionsResults {
  padding: 0 10px;
}
