.storesList {
  max-height: 400px;
  overflow-y: scroll;
}

.storesList__storePreview {
  cursor: pointer;
  border-top: 1px solid;
  border-color: rgb(241, 241, 241);
  padding: 10px;
  display: flex;
}

.storesList__storePreview:hover {
  background: rgb(241, 241, 241);
}

.container {
  margin: 2px 10px !important;
  border-bottom: 1px solid #ccc !important;
}

.storePreview__storeDistance--distance {
  text-align: center;
}

.storePreview__storeDistance--icon {
  text-align: center;
}
