body {
  height: 100%;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  font-family: "GT-Eesti" !important;
  letter-spacing: -0.01em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.storeLocator {
}
