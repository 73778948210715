.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* margin-top: calc(100% - 100px); */
  height: calc(100vh - 80px);
  margin-top: auto;
}

.floating-items {
  position: relative;
  /* width: 20%; */
  /* height: auto; */
  right: 40px;
  top: -52px;
  text-align: right;
  font-size: 14px;
}

.legend {
  position: absolute;
  right: 100px;
  text-align: center;
}

.legend-text {
  margin-top: 10px;
}
.legend-icon-container {
  background: #fff;
  /* height: 100%; */
  /* width: 100%; */
  color: gold;
  border: 1px solid #eee;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: auto;
  padding: 2px;
  font-size: 24px;
  box-shadow: -1px -1px 5px 2px #d8d6d6;
  cursor: pointer;
}

.legend-display {
  display: inline;
  position: absolute;
  right: 45px;
  text-align: left;
  top: 80px;
  padding: 10px 20px;
  background: white;
  border: 1px solid #fff;
  border-radius: 5px;
  z-index: 100;
}

.legend-display-items {
  display: flex;
  align-items: center;
  /* margin: 10px; */
  padding: -9px;
}

.legend-display-items:not(:first-child) {
  margin-top: 10px;
}

.legend-display-items > img {
  margin-right: 5px;
}

.back {
  position: absolute;
  right: -15px;
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* margin-top: calc(100% - 100px); */
    height: calc(100vh - 60px);
    margin-top: auto;
  }

  .floating-items {
    position: relative;
    /* width: 20%; */
    /* height: auto; */
    right: 20px;
    top: -52px;
    text-align: right;
    font-size: 14px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
