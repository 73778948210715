.header {
  z-index: 100;

  background: #fff;
}

.header-logo-title {
  position: absolute;
  top: 25px;
  display: block;
  left: 40%;
  font-size: 16px;
}

.header-container {
  width: 60%;
  margin: auto;
  height: 100%;
}

.header-title {
  font-size: 3vh;
  color: #000;
}

.header-title--red {
  color: red;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-logo-title {
    position: absolute;
    top: 25px;
    display: none;
  }
  .header {
    z-index: 100;
    width: 100%;
    background: #fff;
  }

  .header-container {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
  .header-container > img {
    width: 90%;
  }

  .header-title {
    font-size: 3vh;
    color: #000;
    display: none;
  }

  .header-title--red {
    color: red;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header {
    z-index: 100;

    background: #fff;
  }

  .header-logo-title {
    position: absolute;
    top: 25px;
    display: none;
  }

  .header-container {
    width: 60%;
    margin: auto;
    height: 100%;
  }

  .header-title {
    font-size: 3vh;
    color: #000;
    display: none;
  }

  .header-title--red {
    color: red;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .header {
    z-index: 100;

    background: #fff;
  }

  .header-logo-title {
    position: absolute;
    top: 25px;
    display: block;
    left: 40%;
    font-size: 16px;
  }

  .header-container {
    width: 60%;
    margin: auto;
    height: 100%;
  }

  .header-title {
    font-size: 2.5vh;
    color: #000;
    display: inline;
  }

  .header-title--red {
    color: red;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .header {
    z-index: 100;

    background: #fff;
  }
  .header-logo-title {
    position: absolute;
    top: 25px;
    display: block;
    left: 40%;
    font-size: 16px;
  }

  .header-container {
    width: 60%;
    margin: auto;
    height: 100%;
  }

  .header-title {
    font-size: 3vh;
    color: #000;
    display: inline;
  }

  .header-title--red {
    color: red;
  }
}

@media only screen and (max-width: 320px) {
  .header {
    z-index: 100;
    height: 90px;
    background: #fff;
  }
}
